<template lang="pug">
    div

</template>

<script lang="ts">
import Vue from 'vue'
import ApplicationService from '../services/ApplicationService'
export default Vue.extend({
    

    async mounted() {
        const type = this.$route.meta.type
        const oldId = this.$route.params.oldId

        try {
            const instance = type == 'proposition' ? await ApplicationService.getPropositionFromOldId(oldId) : await ApplicationService.getProjectFromOldId(oldId)
            if(instance) {
                this.$router.push({path: '/erp/' + instance.id, query: {type: instance.type}})
            } else {
                this.$router.push('/')    
            }
        } catch(e) {
            this.$router.push('/')
        }
    }
})
</script>

<style lang="scss" scoped>

</style>